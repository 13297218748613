<template>
  <div>
    <div
      class="book">
      <!-- <div
        v-for="(pages, index) in productsInfoPrint"
        :key="pages + index"
        class="page">
        <div
          style="display: flex; flex-wrap: wrap;">
          <div
            v-for="(page, index) in pages"
            :key="page + index">
            <div
              style="width: 155px;"
              class="mt-4">
              <qrcode-vue
                :value="page.code"
                :size="120"
                level="H"
                render-as="svg"
                style="text-align: center;"
              />
              <div style="font-size: 10px; text-align: center; line-height: 20px;">
                <span v-if="showInfo.nameProduct"><span class="font-weight-bold">{{ page.name }}</span></span>
                <span
                  v-if="showInfo.codeProduct"
                  style="display: block;">{{ $t('common.code') }}: <span class="font-weight-bold">{{ page.code }}</span></span>
                <span v-if="showInfo.pointProduct">{{ $t('common.point') }}: <span class="font-weight-bold">{{ page.productPoint }}</span></span>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div
        id="pageId"
        class="page">
        <div
          v-if="productName!=null"
          id="productTitle"
          class="text-center"> {{ productName }} ({{ `${itemPerRow} x ${numberQRRow.length + 1}` }}) </div>
        <div
          id="productQRCodeRow"
          ref="productQRCodeRow"
          class="d-flex"
          style="padding: 0; margin: 0">
          <div
            v-for="(item, index) in numberItemInRowArray"
            :key="item+index"
            style="padding: 4px; margin: 0px">
            <qrcode-vue
              :value="qrCode"
              :size="parseInt(qrCodeSize)"
              level="L"
              render-as="svg"
              style="text-align: center; padding: 5px; border: 1px solid #000; margin: 0px"
            />
          </div>
        </div>
        <div
          v-for="(row, index) in numberQRRow"
          :key="row+index"
          class="d-flex">
          <div
            v-for="(item, index) in numberItemInRowArray"
            :key="item+index"
            style="padding: 4px">
            <qrcode-vue
              :value="qrCode"
              :size="parseInt(qrCodeSize)"
              level="L"
              render-as="svg"
              style="text-align: center; padding: 5px; border: 1px solid #000"
            />
          </div>
        </div>
      </div>
    </div>
    <div
      class="btn-print"
      style="position: fixed; bottom: 0;">
      <v-btn
        class="btn"
        color="warning"
        dark
        @click="onPrintPage"
      >
        <v-icon class="mr-2">mdi-printer</v-icon>
        <span style="text-transform: none;">{{
          $t('common.print')
        }}</span>
      </v-btn>
    </div>
  </div>
</template>

<script>
import QrcodeVue from 'qrcode.vue'
import functionUtils from 'utils/functionUtils'
export default {
  components: {
    QrcodeVue
  },
  data: () => ({
    productsInfoPrint: [],
    showInfo: {},
    itemPerRow: null,
    productName: null,
    qrCode: null,
    qrCodeSize: 0,
    pageHeight: 0,
    productTitle: 0,
    perQRRow: 0,
    numberQRRow: [],
    numberItemInRowArray: []
  }),
  async mounted () {
    var elmnt = await document.getElementById('productQRCodeRow')
    var productTitle = await document.getElementById('productTitle')
    var pageHeight = await document.getElementById('pageId')
    this.pageHeight = pageHeight.clientHeight
    this.productTitle = productTitle.clientHeight
    this.qrCodeSize = (elmnt.clientWidth / this.itemPerRow) - 20
    this.numberQRRow = this.createArray(this.getNumberRow())
  },

  created () {
    let itemPerRow = this.$route.query.itemPerRow
    let qrCode = this.$route.query.qr
    this.itemPerRow = itemPerRow
    this.qrCode = qrCode
    let productName = this.$route.query.name
    this.productName = productName.length > 100 ? functionUtils.spliceString(productName, 100) + '...' : productName
    this.numberItemInRowArray = this.createArray(itemPerRow)
  },
  methods: {
    getNumberRow: function () {
      let value = this.pageHeight - (parseInt(this.qrCodeSize) + 20) - this.productTitle
      return parseInt(value / (this.qrCodeSize + 20 + 5)) - 1
    },
    /**
     * Print page
     */
    onPrintPage: function () {
      window.print()
    },
    createArray: function (value) {
      let array = Array.from(Array(parseInt(value)).keys())
      return array
    }
  }
}
</script>

<style lang="scss">
body.print {
    overflow: initial;
}
 body.print {
        width: 100%;
        height: 100%;
        margin: 0;
        padding: 0;
        background-color: #FAFAFA;
        font: 12pt;
    }
    * {
        box-sizing: border-box;
        -moz-box-sizing: border-box;
    }
    .page {
        width: 210mm;
        height: 297mm;
        padding: 2mm;
        margin: 10mm auto;
        border: 1px #D3D3D3 solid;
        border-radius: 5px;
        background: white;
        box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);
    }
    .subpage {
        height: 257mm;
    }

    @page {
        size: A4;
        margin: 0;
    }
    @media print {
        html {
            width: 100%;
            height: 297mm;
        }
        body.print {
            width: 100%;
            height: 297mm;
        }
        .page {
            margin: 0;
            border: initial;
            border-radius: initial;
            width: initial;
            min-height: initial;
            box-shadow: initial;
            background: initial;
            page-break-after: always;
        }
        .btn-print {
            display: none;
        }
    }
.theme--dark.v-sheet {
    padding: 5px;
}
</style>
